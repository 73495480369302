import { NumberColumnDisplayFormat } from "../display-table/columnDisplayFormatTypes.js";
import { COUNT_STAR_LABEL } from "../explore/exploreFieldUtils.js";

import { ChartAggregate, ChartDataType } from "./types.js";

export const CHART_AGGREGATE_MAX_VALUE_COUNT = 100;
export const CHART_PIVOT_TOOLTIP_MAX_SERIES = 20;
export const CHART_DEFAULT_RULE_COLOR_MARKER =
  "CHART_DEFAULT_RULE_COLOR_MARKER";

export const CHART_AXIS_TITLE_MAX_LEN = 80;
export const CHART_MAX_FACETS = 100; //sync with chart_cell.py

export const DEFAULT_CHART_RESULT_VARIABLE = "chart_result";

export const CHART_INTERVAL_SELECTION_NAME = "interval_intervalselection_";
export const CHART_LEGEND_SELECTION_NAME = "legend_pointselection_";
export const CHART_POINT_SELECTION_NAME = "click_pointselection_";
export const CHART_LEGEND_HOVER_NAME = "legend_pointhover_";

export const CHART_AUTO_LIGHT_DARK_COLOR_PLACEHOLDER =
  "CHART_AUTO_LIGHT_DARK_COLOR_PLACEHOLDER";

export const CHART_DEFAULT_NUMBER_FORMAT: NumberColumnDisplayFormat = {
  format: "NUMBER",
  columnType: "NUMBER",
  numDecimalDigits: -1,
  currency: "USD",
  nanFormat: "",
};
export const CHART_DEFAULT_PCTSIGFIG_FORMAT: NumberColumnDisplayFormat = {
  format: "PERCENT_2SIGFIG",
  columnType: "NUMBER",
  numDecimalDigits: 0,
  currency: "USD",
  abbreviateLargeNumbers: false,
  nanFormat: "",
};

export const SUM_Y_BY_X = "sum_y_by_x";

export const AGGREGATES_BY_DATATYPE: {
  [key in ChartDataType]: readonly ChartAggregate[];
} = {
  string: [
    "count",
    "valid",
    "distinct",
    "sum",
    "average",
    "median",
    "max",
    "min",
    "stderr",
    "stdev",
    "stdevp",
    "variance",
    "variancep",
    // "missing", // disabled until fix for VIS-439
  ],
  number: [
    "count",
    "valid",
    "sum",
    "average",
    "median",
    "distinct",
    "max",
    "min",
    // "stderr", // unsupported by HQL
    "stdev",
    "stdevp",
    "variance",
    "variancep",
    // "missing", // disabled until fix for VIS-439
  ],
  datetime: [
    "count",
    "valid",
    "distinct",
    // "max", // disabled until fix for VIS-440
    // "min", // disabled until fix for VIS-440
    // "missing", // disabled until fix for VIS-439
  ],
};

export const AGGREGATE_TITLES: { [key in ChartAggregate]: string } = {
  count: COUNT_STAR_LABEL,
  valid: "Count Non-Null",
  sum: "Sum",
  average: "Average",
  distinct: "Count Distinct",
  median: "Median",
  max: "Maximum",
  min: "Minimum",
  stderr: "Standard Error",
  stdev: "Standard Deviation",
  stdevp: "Pop. Std. Deviation",
  variance: "Variance",
  variancep: "Pop. Variance",
  // aggregates below are not included in the lists above
  ci0: "CI 0",
  ci1: "CI 1",
  mean: "Mean",
  missing: "Missing",
  product: "Product",
  q1: "Q1",
  q3: "Q3",
  values: "Values",
};

export function aggregateAlwaysPossible(aggregate: ChartAggregate): boolean {
  return Object.values(AGGREGATES_BY_DATATYPE).every((ops) =>
    ops.includes(aggregate),
  );
}

export function aggregateIsSummable(aggregate: ChartAggregate): boolean {
  return ["sum", "count", "valid"].includes(aggregate);
}

export const DATA_LABEL_BUFFER_PX = 5;
export const DATA_LABEL_BAR_INSIDE_BUFFER_PX = 4;
export const DATA_LABEL_BAR_OUTSIDE_BUFFER_PX = 2;
export const VEGA_LITE_DEFAULT_FONT_SIZE = 11;

export const DATA_LABEL_FORMATTED_FIELD_NAME = "__formatted_data_label__";
