import { Edge, Node } from "@xyflow/react";
import { GraphNodeV3, VariableName } from "@hex/common";
import { CellMP } from "../../redux/slices/hexVersionMPSlice";
import { ViewType } from "../../route/routes";

export enum FlowOrientation {
  Vertical = "Vertical",
  Horizontal = "Horizontal",
}

export interface HexNodeOptions extends Record<string, unknown> {
  hideImports: boolean;
  node: GraphNodeV3<CellMP>;
  index: number;
  view: ViewType;
  edgeType: "combined" | "separate";
}

export type HexNodeType = Node<HexNodeOptions, "hexNode">;

export interface HexEdgeOptions extends Record<string, unknown> {
  variable: VariableName;
  child: GraphNodeV3<CellMP>;
}

export interface HexCombinedEdgeOptions extends Record<string, unknown> {
  variables: VariableName[];
}

export type HexEdgeType = Edge<HexEdgeOptions, "hexEdge">;
export type HexCombinedEdgeType = Edge<
  HexCombinedEdgeOptions,
  "hexCombinedEdge"
>;
//export type HexEdgeTypes = HexEdgeType | HexCombinedEdgeType;
export type HexEdgeTypes =
  | Edge<HexEdgeOptions, "hexEdge">
  | Edge<HexCombinedEdgeOptions, "hexCombinedEdge">;
