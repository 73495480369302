import { DOCS_LINKS } from "@hex/common";
import { sample, shuffle } from "lodash";
import { rgba } from "polished";
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import styled, { css } from "styled-components";

import { DocsLink } from "../common/DocsLink";
import { DataConnectionIcon } from "../data/DataConnectionIcon";
import { Heading } from "../Heading";
import {
  ChartCellIcon,
  CodeIcon,
  DataSourcesIcon,
  LibraryIcon,
  PublishIcon,
  QuoteIcon,
  SQLCellIcon,
} from "../icons/CustomIcons";

interface CustomerDataItem {
  author?: string;
  logoPath: string;
  name: string;
  quote?: string;
  slug: string;
  synopsis?: string;
  inHome: boolean;
}

interface CustomerDataPayload {
  feed_url: string;
  home_page_url: string;
  items: CustomerDataItem[];
  next_feed_url?: string;
  previous_feed_url?: string;
  version: string;
}

interface CustomerDataResponse {
  status: string;
  payload?: CustomerDataPayload;
  error?: Error;
}

interface HighlightBullet {
  icon: ReactNode;
  text: ReactNode;
}

interface PartnerSite {
  partnerLink: string;
  icon?: ReactNode;
  description?: ReactNode;
  title?: string;
  bullets?: HighlightBullet[];
}

const PARTNER_SITE_INFO: PartnerSite[] = [
  {
    partnerLink: "bigquery",
    icon: (
      <DataConnectionIcon
        connectionType="bigquery"
        minimalIconSize="extra-large"
      />
    ),
    title: "Power interactive insights with data from Google BigQuery",
    description: (
      <div>
        Hex connects directly to{" "}
        <DocsLink to={DOCS_LINKS.DataConnections}>Google BigQuery</DocsLink>{" "}
        <span>
          to let you query big data with familiar SQL syntax, without exporting
          csv files or needing a Python connector.
        </span>
      </div>
    ),
  },
  {
    partnerLink: "snowflake",
    icon: (
      <DataConnectionIcon
        connectionType="snowflake"
        minimalIconSize="extra-large"
      />
    ),
    title: "Query Snowflake data right from a powerful data notebook",
    description: (
      <div>
        <div>
          &ldquo;Hex and Snowpark together{" "}
          <span>
            will help us truly democratize data for users across the
            business.&rdquo;
          </span>
        </div>
        <div>
          <label>Abhishek Modi, Software Engineer at Notion</label>
        </div>
      </div>
    ),
    bullets: [
      {
        icon: <CodeIcon />,
        text: "Ask and answer questions with SQL & Python easily with native Snowflake connectors",
      },
      {
        icon: <SQLCellIcon />,
        text: (
          <span>
            Query any amount of data with pre-built{" "}
            <DocsLink to={DOCS_LINKS.SnowparkIntegration}>
              Snowpark integration
            </DocsLink>{" "}
            and <DocsLink to={DOCS_LINKS.QueryMode}>Query mode</DocsLink>
          </span>
        ),
      },
      {
        icon: <ChartCellIcon />,
        text: "Create and share interactive visualizations without writing code",
      },
    ],
  },
];

const DEFAULT_BULLETS = [
  {
    icon: <CodeIcon />,
    text: "Ask and answer questions with SQL, Python, R, & no-code",
  },
  {
    icon: <PublishIcon />,
    text: "Create and share interactive data apps",
  },
  {
    icon: <LibraryIcon />,
    text: "Build a library of knowledge for your entire company",
  },
];

const Wrapper = styled.div<{ $isVisible: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 460px;

  color: ${({ theme }) => theme.marketingColors.ROSE_QUARTZ};

  opacity: 0;

  transition: opacity ${({ theme }) => theme.animation.durationMedium}
    ${({ theme }) => theme.animation.easing};

  ${({ $isVisible }) =>
    $isVisible &&
    css`
      opacity: 1;
    `}

  @media (max-width: ${({ theme }) => `${theme.mediaQuery.SMALL}px`}) {
    display: none;
  }

  &:before,
  &:after {
    margin: auto;

    content: "";
  }
`;

const Info = styled.div`
  margin-bottom: 60px;
`;

const InfoTop = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 25px;
`;

const InfoIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;

  border: 1px solid
    ${({ theme }) => rgba(theme.marketingColors.ROSE_QUARTZ, 0.35)};
`;

// eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
const InfoTitle = styled(Heading).attrs({ renderAs: "h2" })`
  max-width: 350px;

  color: ${({ theme }) => theme.marketingColors.OPAL};

  span {
    color: ${({ theme }) => theme.marketingColors.ROSE_QUARTZ};
  }
`;

const InfoHeading = styled.div`
  display: inline;

  color: ${({ theme }) => theme.marketingColors.OPAL};
`;

const Bullet = styled.div`
  display: flex;
  gap: 10px;
  align-items: flex-start;
  margin-bottom: 15px;

  color: ${({ theme }) => theme.marketingColors.ROSE_QUARTZ};
  font-size: ${({ theme }) => theme.fontSize.LARGE};
  line-height: 22px;

  &:last-child {
    margin-bottom: 0;
  }

  svg {
    color: ${({ theme }) => theme.marketingColors.ROSE_QUARTZ};
  }

  && {
    a {
      color: inherit;
      text-decoration: underline;
    }
  }
`;

const BulletIcon = styled.div`
  display: flex;
  align-items: center;
  height: 22px;
`;

const TestimonialIcon = styled.div`
  position: absolute;
  top: 4px;
  left: 0;

  display: flex;

  svg {
    color: ${({ theme }) => theme.marketingColors.ROSE_QUARTZ};
  }
`;

const TestimonialInfo = styled.div`
  position: relative;

  width: 100%;

  padding-left: 30px;

  &::before {
    position: absolute;
    top: 30px;
    bottom: -10px;
    left: 7px;

    display: flex;
    flex: 1 1 auto;
    width: 1px;

    background: linear-gradient(
      0deg,
      transparent 0%,
      ${({ theme }) => rgba(theme.marketingColors.ROSE_QUARTZ, 0.5)} 95%
    );

    content: "";
  }
`;

const TestimonialInner = styled.div`
  margin-bottom: 35px;

  color: ${({ theme }) => theme.marketingColors.OPAL};
`;

const TestimonialText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 12px;

  color: ${({ theme }) => theme.marketingColors.OPAL};

  font-size: ${({ theme }) => theme.fontSize.LARGE};
  line-height: 22px;

  span {
    color: ${({ theme }) => theme.marketingColors.ROSE_QUARTZ};
  }

  label {
    display: block;
    padding-top: 5px;

    color: ${({ theme }) => theme.marketingColors.ROSE_QUARTZ};
    font-size: ${({ theme }) => theme.fontSize.DEFAULT};

    opacity: 0.6;
  }

  && {
    a {
      color: inherit;
      text-decoration: underline;
    }
  }
`;

const Attribution = styled.div`
  color: ${({ theme }) => theme.marketingColors.ROSE_QUARTZ};
  font-size: ${({ theme }) => theme.fontSize.DEFAULT};
`;

const Author = styled.div`
  margin-bottom: 3px;
`;

const Company = styled.div`
  color: ${({ theme }) => rgba(theme.marketingColors.ROSE_QUARTZ, 0.6)};
  line-height: 18px;

  a {
    color: ${({ theme }) => rgba(theme.marketingColors.ROSE_QUARTZ, 0.6)};
    text-decoration: underline;
  }
`;

// eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
const StyledHeading = styled(Heading).attrs({ renderAs: "h5" })`
  color: ${({ theme }) => theme.marketingColors.ROSE_QUARTZ};
`;

const TrustedBy = styled.div`
  display: flex;
  flex-direction: column;
`;

const LogoGrid = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 12px;

  img {
    height: 35px;
    padding: 0 4px;
  }
`;

interface MarketingAndTestimonialsProps {
  partnerLink: string | null;
}

export const MarketingAndTestimonials: React.ComponentType<MarketingAndTestimonialsProps> =
  React.memo(function MarketingAndTestimonials({ partnerLink }) {
    const [customerData, setCustomerData] = useState<CustomerDataResponse>({
      status: "loading",
    });

    const retryCustomerData = useCallback(() => {
      fetch("https://hex.tech/customersFeed-1.json")
        .then((response) => response.json())
        .then((response) =>
          setCustomerData({ status: "loaded", payload: response }),
        )
        .catch((error) => setCustomerData({ status: "error", error }));
    }, []);

    useEffect(() => {
      retryCustomerData();
    }, [retryCustomerData]);

    const customerQuote = useMemo(() => {
      return sample(customerData.payload?.items.filter((o) => o.quote));
    }, [customerData.payload?.items]);

    const customerLogos = useMemo(() => {
      // Remove customer quote logo from options
      const possibleLogos = customerData.payload
        ? customerData.payload?.items
        : [];

      // Filter out those not inHome and already returned as a quote
      const filteredLogos = [...possibleLogos].filter(
        (o) => o.inHome && o.slug !== customerQuote?.slug,
      );

      return shuffle(filteredLogos.slice(0, 3));
    }, [customerData.payload, customerQuote?.slug]);

    const partnerSiteInfo = useMemo(
      () => PARTNER_SITE_INFO.find((o) => o.partnerLink === partnerLink),
      [partnerLink],
    );

    const isQuote = useMemo(
      () =>
        customerQuote != null &&
        partnerSiteInfo?.title == null &&
        partnerSiteInfo?.description == null,
      [customerQuote, partnerSiteInfo],
    );

    const infoTitle = useMemo(
      () =>
        partnerSiteInfo?.title ?? (
          <>
            <span>No matter your team size,</span>{" "}
            <InfoHeading>
              Hex makes working with data more efficient and impactful.
            </InfoHeading>
          </>
        ),
      [partnerSiteInfo?.title],
    );

    const infoBullets = useMemo(
      () => partnerSiteInfo?.bullets ?? DEFAULT_BULLETS,
      [partnerSiteInfo?.bullets],
    );

    // Don't show if there is an error
    if (customerData.status === "error") {
      return null;
    }

    return (
      <Wrapper $isVisible={customerData.status === "loaded"}>
        <Info>
          <InfoTop>
            {partnerSiteInfo?.icon && (
              <InfoIcon>{partnerSiteInfo.icon}</InfoIcon>
            )}
            <InfoTitle>{infoTitle}</InfoTitle>
          </InfoTop>
          {infoBullets.map((item, i) => (
            <Bullet key={i}>
              <BulletIcon>{item.icon}</BulletIcon>
              <div>{item.text}</div>
            </Bullet>
          ))}
        </Info>
        <TestimonialInfo>
          <TestimonialIcon>
            {isQuote ? <QuoteIcon /> : <DataSourcesIcon />}
          </TestimonialIcon>
          <TestimonialInner>
            {isQuote ? (
              <>
                <TestimonialText>{customerQuote?.synopsis}</TestimonialText>
                <Attribution>
                  <Author>{customerQuote?.author}</Author>
                  <Company>
                    {customerQuote?.name} |{" "}
                    <a
                      href={
                        customerData.payload?.home_page_url +
                        "/customers/" +
                        customerQuote?.slug
                      }
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Read their story
                    </a>
                  </Company>
                </Attribution>
              </>
            ) : (
              <TestimonialText>{partnerSiteInfo?.description}</TestimonialText>
            )}
          </TestimonialInner>
          {customerLogos.length > 0 && (
            <TrustedBy>
              <StyledHeading>Trusted by data teams at</StyledHeading>
              <LogoGrid>
                <img
                  key={customerQuote?.slug}
                  alt={customerQuote?.name}
                  src={customerQuote?.logoPath}
                />
                {customerLogos?.map((item, index) => (
                  <img key={index} alt={item.name} src={item.logoPath} />
                ))}
              </LogoGrid>
            </TrustedBy>
          )}
        </TestimonialInfo>
      </Wrapper>
    );
  });
