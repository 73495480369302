import {
  CalcColumnDefinition,
  CalciteType,
  ColumnFilter,
  DisplayTableColumnId,
  ExploreChartConfig,
  ExploreDetailField,
  ExploreField,
  ExploreSpec,
  FilledDynamicValueTableColumnType,
  HqlAggregationFunction,
  SemanticAwareColumn,
  SemanticAwareFieldGroup,
  assertNever,
  calciteTypeToChartDataType,
  calciteTypeToColumnType,
  columnTypeToCalciteType,
  generateColumnIdForField,
  getAllNodesOfType,
  isPie,
  joinDatasetQueryPath,
  parseCalcExpression,
  sentenceCase,
  typedObjectEntries,
} from "@hex/common";

import { ExploreDropSection } from "./dnd/ExploreDndTypes.js";

export function getFieldLabel(
  field: ExploreField | ExploreDetailField,
): string {
  if ("title" in field && field.title != null) {
    return field.title;
  }
  let label = field.value;

  if ("aggregation" in field && field.aggregation != null) {
    label = `${getFieldAggregationLabel(field.aggregation)} of ${label}`;
  }
  if (field.queryPath != null && field.queryPath.length > 0) {
    label = `${label} (${joinDatasetQueryPath(field.queryPath)})`;
  }

  return label;
}

export function getFieldAggregationLabel(
  aggregation: HqlAggregationFunction,
): string {
  return aggregation === "CountDistinct"
    ? "Count distinct"
    : sentenceCase(aggregation) ?? "";
}

export function isDatetimeType(dataType: CalciteType): boolean {
  return calciteTypeToChartDataType(dataType) === "datetime";
}

export function isNumericType(dataType: CalciteType): boolean {
  return calciteTypeToChartDataType(dataType) === "number";
}

const LabelBySection: Record<ExploreDropSection["type"], string> = {
  "base-axis": "X-axis",
  "cross-axis": "Y-axis",
  "h-facet": "horizontal split",
  "v-facet": "vertical split",
  color: "color by",
  tooltip: "tooltip",
  row: "row",
  column: "column",
  value: "value",
  opacity: "opacity",
  "new-series": "new series",
  "new-group": "new Y-axis",
  aggregation: "aggregation",
  detail: "detail",
  groupby: "group by",
};

export function getLabelForChannel(
  channel: ExploreDropSection["type"],
  chartConfig?: ExploreChartConfig,
): string {
  if (chartConfig != null) {
    if (channel === "base-axis" && chartConfig.orientation === "horizontal") {
      return "Y-axis";
    }

    if (channel === "cross-axis" && isPie(chartConfig)) {
      return "Size";
    }
    if (channel === "cross-axis" && chartConfig.orientation === "horizontal") {
      return "X-axis";
    }
  }
  return LabelBySection[channel];
}

export const EXPLORE_CHART_ONLY_AGGREGATIONS: Set<HqlAggregationFunction> =
  new Set(["StdDev", "StdDevPop", "Variance", "VariancePop"]);

export type ExploreFieldOutOfSync = {
  fieldId: string;
  error: ExploreFieldOutOfSyncError;

  usedInViz?: boolean;
  usedInDetails?: boolean;
  usedInFilters?: boolean;
  calcColumnNames: DisplayTableColumnId[];
};

export type ExploreFieldOutOfSyncError =
  | {
      type: "missing";
    }
  | {
      type: "incompatible-type";
      expectedType: FilledDynamicValueTableColumnType;
      currentType: FilledDynamicValueTableColumnType;
    };

export const flattenFieldGroups = (
  fieldGroups: SemanticAwareFieldGroup[],
): SemanticAwareColumn[] =>
  fieldGroups.flatMap((fg) => [
    ...fg.columns,
    ...fg.measures,
    ...flattenFieldGroups(fg.children),
  ]);

export const getTypeCategory = (
  dataType: FilledDynamicValueTableColumnType | CalciteType,
) => {
  switch (dataType) {
    case "NUMBER":
    case "FLOAT":
    case "DOUBLE":
    case "BIGINT":
    case "INTEGER":
    case "DECIMAL":
      return "numeric";
    case "STRING":
    case "VARCHAR":
      return "string";
    case "BOOLEAN":
      return "boolean";
    case "DATE":
    case "TIME":
    case "DATETIME":
    case "DATETIMETZ":
    case "TIMESTAMP":
    case "TIMESTAMPTZ":
      return "datetime";
    case "UNKNOWN":
      return "unknown";
    default:
      assertNever(dataType, dataType);
  }
};

export const getExploreFieldsOutOfSync = (
  spec: ExploreSpec,
  calcColumns: readonly CalcColumnDefinition[],
  filters: ColumnFilter[],
  fieldGroups: SemanticAwareFieldGroup[],
): ExploreFieldOutOfSync[] => {
  const referencedColumnToCalcColumn =
    getReferencedColumnToCalcColumn(calcColumns);
  const flatFields = flattenFieldGroups(fieldGroups);
  const fieldLookup = new Map(
    flatFields.map((f) => [generateColumnIdForField(f), f]),
  );
  // The same field might pop up in a few places
  // It might not have an error; that's for the loop after this one to decide
  const uniqFieldsToPartialResult: Record<
    string,
    Omit<ExploreFieldOutOfSync, "error"> & { dataType: CalciteType }
  > = {};
  [
    ...spec.fields,
    ...(spec.details.enabled ? spec.details.fields ?? [] : []),
    ...filters.map(
      (filter) =>
        ({
          value: filter.column,
          queryPath: filter.queryPath ?? [],
          // A little silly to convert back and forth
          dataType: columnTypeToCalciteType(filter.columnType ?? "UNKNOWN"),
          location: "filter",
        }) as const,
    ),
  ].forEach((field) => {
    const key = generateColumnIdForField(field);
    if (uniqFieldsToPartialResult[key] == null) {
      uniqFieldsToPartialResult[key] = {
        fieldId: key,
        usedInViz: "channel" in field,
        usedInDetails: "visible" in field,
        usedInFilters: "location" in field && field.location === "filter",
        calcColumnNames: referencedColumnToCalcColumn[key] ?? [],
        dataType: field.dataType,
      };
    } else {
      uniqFieldsToPartialResult[key].usedInViz ||= "channel" in field;
      uniqFieldsToPartialResult[key].usedInDetails ||= "visible" in field;
      uniqFieldsToPartialResult[key].usedInFilters ||= "location" in field;
    }
  });

  const outOfSyncFields: ExploreFieldOutOfSync[] = [];

  for (const [fieldId, partialResult] of typedObjectEntries(
    uniqFieldsToPartialResult,
  )) {
    const sameFieldFromSchema = fieldLookup.get(fieldId);

    if (sameFieldFromSchema == null) {
      outOfSyncFields.push({
        ...partialResult,
        error: { type: "missing" } as const,
      });
      continue;
    }
    if (
      getTypeCategory(partialResult.dataType) !==
      getTypeCategory(sameFieldFromSchema.columnType)
    ) {
      outOfSyncFields.push({
        ...partialResult,
        error: {
          type: "incompatible-type",
          expectedType: calciteTypeToColumnType(partialResult.dataType),
          currentType: sameFieldFromSchema.columnType,
        } as const,
      });
    }
  }

  return outOfSyncFields;
};

export const getReferencedColumnToCalcColumn = (
  calcColumns: readonly CalcColumnDefinition[],
): Record<string, DisplayTableColumnId[]> => {
  const allASTs = calcColumns.map((c) => parseCalcExpression(c.expression));
  const referencedColumnToCalcColumn: Record<string, DisplayTableColumnId[]> =
    {};
  calcColumns.forEach((calcColumn, i) => {
    const ast = allASTs[i];
    const referencedColumns = getAllNodesOfType(ast, "column").map(
      ({ name }) => name,
    );
    referencedColumns.forEach((column) => {
      if (!referencedColumnToCalcColumn[column]) {
        referencedColumnToCalcColumn[column] = [];
      }
      referencedColumnToCalcColumn[column].push(calcColumn.name);
    });
  });
  return referencedColumnToCalcColumn;
};
