import {
  AnnouncementId,
  AnnouncementLocation,
  AnnouncementType,
  CellId,
  CellType,
  CommentableEntity,
  DataBrowserTab,
  DataConnectionId,
  HexId,
  HexVersionId,
  OrgId,
  OrgRole,
  ProjectRole,
  RichTextDocument,
  StringDate,
} from "@hex/common";
import { EntityId } from "@reduxjs/toolkit";
import { Static, String } from "runtypes";

import { UsageLimit } from "../components/app/FeatureGateBanner";
import { ExploreViewType } from "../components/cell/renderers/query-cells-shared/QueryLogicCellFooter.js";
import { SemanticAwareFieldListSort } from "../components/common/SemanticAwareFieldList.js";
import { DEFAULT_SIDEBAR_WIDTH } from "../components/sidebar/SidebarOptions";
import { Routes } from "../route/routes.js";
import { DEFAULT_SPLIT_WIDTH } from "../route/user/project/constants";
import { DEFAULT_THEME } from "../theme/common/theme";

import {
  BrowserStorageKey,
  useBrowserStorageGetter,
  useBrowserStorageSetter,
  useBrowserStorageValue,
  useDynamicBrowserStorageGetter,
  useDynamicBrowserStorageSetter,
} from "./useBrowserStorage.js";
import { FlowOrientation } from "../components/graph/types";

// eslint-disable-next-line tree-shaking/no-side-effects-in-initialization
const InAppAnnouncementKey = String.withBrand("inAppAnnouncementKey");
export type InAppAnnouncementKey = Static<typeof InAppAnnouncementKey>;

export const getInAppAnnouncementKey = ({
  location,
  type,
}: {
  location: AnnouncementLocation;
  type: AnnouncementType;
}): InAppAnnouncementKey => InAppAnnouncementKey.check(`${location}-${type}`);

export type InAppAnnouncementSchedule = Record<
  AnnouncementId,
  Record<InAppAnnouncementKey, StringDate>
>;

export interface StoredComment {
  entityId: EntityId;
  entityType: CommentableEntity;
  hexVersionId: HexVersionId;
  comment: RichTextDocument;
}

export type CompletionSettings = Record<string, number | string | boolean>;

export const LocalStorageKeys = {
  IN_APP_ANNOUNCEMENTS: (): BrowserStorageKey<InAppAnnouncementSchedule> => ({
    keyName: "in_app_announcements",
    default: {},
  }),
  IN_APP_RELEASES: (): BrowserStorageKey<Record<string, boolean>> => ({
    keyName: "in_app_releases",
    default: {},
  }),
  PROJECT_COMMENTS_SIDEBAR_OPEN: {
    keyName: "project_comments_sidebar_open",
    default: false,
  },
  CELL_SOURCE_COLLAPSED: (cellId: CellId): BrowserStorageKey<boolean> => ({
    keyName: `cell_${cellId}_source_collapsed`,
    default: false,
  }),
  CELL_OUTPUT_COLLAPSED: (cellId: CellId): BrowserStorageKey<boolean> => ({
    keyName: `cell_${cellId}_output_collapsed`,
    default: false,
  }),
  THEME_NAME: { keyName: "theme_name", default: DEFAULT_THEME },
  FULL_WIDTH_LOGIC_CELLS: (): BrowserStorageKey<boolean> => ({
    keyName: "full_width_logic_cells",
    default: true,
  }),
  DIALOG_FULL_WIDTH_LOGIC_CELLS: {
    keyName: "dialog_full_width_logic_cells",
    default: false,
  },
  ACTION_BAR_COLLAPSED: { keyName: "action_bar_collapsed", default: false },
  SIDEBAR_FAVORITE_COLLECTIONS_OPENED: {
    keyName: "SIDEBAR_FAVORITE_COLLECTIONS_OPENED",
    default: true,
  },
  SIDEBAR_FAVORITE_PROJECTS_OPENED: {
    keyName: "SIDEBAR_FAVORITE_PROJECTS_OPENED",
    default: true,
  },
  SIDEBAR_FAVORITE_DATA_OPENED: {
    keyName: "SIDEBAR_FAVORITE_DATA_OPENED",
    default: true,
  },
  APP_SETTINGS_OPEN: {
    keyName: "app_settings_open",
    default: true,
  },
  QA_SIDEBAR_WIDTH: {
    keyName: "qa_sidebar_width",
    default: DEFAULT_SIDEBAR_WIDTH,
  },
  SPLIT_WIDTH: { keyName: "split_width", default: DEFAULT_SPLIT_WIDTH },
  SPLIT_WIDTH_VERT: {
    keyName: "split_width_vert",
    default: DEFAULT_SPLIT_WIDTH,
  },

  HAS_DISMISSED_HEX_CELL_LINK_COMPONENTS_REMINDER: {
    keyName: "has_dismissed_hex_cell_link_components_reminder",
    default: false,
  },

  HAS_DISMISSED_GET_STARTED_BANNER: {
    keyName: "has_dismissed_get_started_banner",
    default: false,
  },

  HAS_DISMISSED_USAGE_LIMIT_BANNER: (
    usageLimit: UsageLimit,
  ): BrowserStorageKey<StringDate | null> => ({
    keyName: `has_dismissed_${usageLimit}_usage_limit_banner`,
    default: null,
  }),

  SCHEMA_BROWSER_CONNECTION: (): BrowserStorageKey<
    DataConnectionId | HexId | undefined
  > => ({
    keyName: "schema_browser_connection",
    default: undefined,
  }),

  ROLE_ON_HEX_REQUESTED: ({
    hexId,
    requestedRole,
  }: {
    hexId: HexId;
    requestedRole: ProjectRole;
  }): BrowserStorageKey<boolean> => ({
    keyName: `hex_${hexId}_role_requested_${requestedRole}`,
    default: false,
  }),

  ORG_ROLE_REQUESTED: ({
    orgId,
    requestedOrgRole,
  }: {
    requestedOrgRole: OrgRole;
    orgId: OrgId;
  }): BrowserStorageKey<boolean> => ({
    keyName: `org_${orgId}_org_role_requested_${requestedOrgRole}`,
    default: false,
  }),

  SIDEBAR_SEARCH_TERM: ({
    hexId,
  }: {
    hexId: HexId;
  }): BrowserStorageKey<string> => ({
    keyName: `hex_${hexId}_search_term`,
    default: "",
  }),

  // List of HexId, searchTerm[] tuples ordered from most -> least recent
  // ProjectSearchView saves only the most recent 5 terms for the most recent 5 projects
  SIDEBAR_RECENTS: (): BrowserStorageKey<
    [HexId, readonly string[]][] | null
  > => ({
    keyName: `recent_sidebar_searches`,
    default: null,
  }),

  GRAPH_OPTION: {
    HIDE_IMPORTS: {
      keyName: `hex_graph_option_hideImports`,
      default: false,
    },
    COMBINE_EDGES: {
      keyName: `hex_graph_option_combineEdges`,
      default: true,
    },
    HIDE_UNLINKED_CELLS: {
      keyName: `hex_graph_option_hideUnlinkedCells`,
      default: false,
    },
    SHOW_LAST_RUN_TIMES: {
      keyName: `hex_graph_option_showLastRunTimes`,
      default: true,
    },
    FLOW_ORIENTATION: {
      keyName: `hex_graph_option_flowOrientation`,
      default: FlowOrientation.Vertical,
    },
    SHOW_MINI_MAP: {
      keyName: `hex_graph_option_showMiniMap`,
      default: true,
    },
  },

  GRAPH_STATE: ({
    hexId,
    option,
  }: {
    hexId: HexId;
    option: "savedGraphState";
  }): BrowserStorageKey<Record<"x" | "y" | "zoom", number> | null> => ({
    keyName: `hex_${hexId}_graph_option_${option}`,
    default: null,
  }),

  CHART_DEMO_EDITOR_SPEC: {
    keyName: "chart_demo_editor_spec",
    default: "",
  },

  COMMENT_STATE: (): BrowserStorageKey<StoredComment | null> => ({
    keyName: "hex_comment",
    default: null,
  }),

  JINJA_INPUT_ONBOARDING_DISMISSED: {
    keyName: "jinja_input_onboarding_dismissed",
    default: false,
  },
  UNTITLED_PROJECT_SHARE_WARNING_DISMISSED: (
    hexId: HexId,
  ): BrowserStorageKey<boolean> => ({
    keyName: `hex_${hexId}_untitled_project_share_warning_dismissed`,
    default: false,
  }),
  NOTIFY_ON_PUBLISH: (hexId: HexId): BrowserStorageKey<boolean> => ({
    keyName: `hex_${hexId}_notify_on_publish`,
    default: true,
  }),
  EXPLORE_STATE: {
    CHART_HEIGHT: ({
      hexId,
      initialHeight,
    }: {
      hexId: HexId;
      initialHeight: number;
    }): BrowserStorageKey<number> => ({
      keyName: `hex_${hexId}_explore_state_chart_height`,
      default: initialHeight,
    }),
    VIEW_TYPE: ({
      hexId,
      initialViewType,
    }: {
      hexId: HexId;
      initialViewType: ExploreViewType;
    }): BrowserStorageKey<ExploreViewType> => ({
      keyName: `hex_${hexId}_explore_state_view_type`,
      default: initialViewType,
    }),
  },
  DATA_BROWSER_VIEW: (): BrowserStorageKey<DataBrowserTab> => ({
    keyName: "data_browser_view",
    default: DataBrowserTab.DATA,
  }),
  WORKSPACE_PAGE_LAYOUT: (): BrowserStorageKey<"card" | "list"> => ({
    keyName: "hex_workspace_page_layout",
    default: "list",
  }),
  WORKSPACE_LAST_VISITED_PAGE: {
    keyName: "hex_workspace_last_visited",
    default: Routes.HOME.getUrl({}),
  },
  WORKSPACE_PROJECTS_TABLE_CONFIG: {
    keyName: "workspace_projects_table_config",
    default: undefined,
  },
  LAST_MARKDOWN_OR_TEXT_CELL: (): BrowserStorageKey<
    typeof CellType.TEXT | typeof CellType.MARKDOWN
  > => ({
    keyName: "markdown_or_text_cell",
    default: CellType.TEXT,
  }),

  EXPLORE_FIELD_LIST_SORT: {
    keyName: "explore_field_list_sort",
    default: SemanticAwareFieldListSort.DATA_TYPE,
  },
};

export function useLocalStorageGetter<V>(key: BrowserStorageKey<V>): () => V {
  return useBrowserStorageGetter("Local", key);
}
export function useDynamicLocalStorageGetter(): <V>(
  key: BrowserStorageKey<V>,
) => V {
  return useDynamicBrowserStorageGetter("Local");
}
export function useLocalStorageSetter<V>(
  key: BrowserStorageKey<V>,
): (value: ((oldValue: V) => V) | V) => void {
  return useBrowserStorageSetter("Local", key);
}
export function useDynamicLocalStorageSetter(): <V>(
  key: BrowserStorageKey<V>,
  value: ((oldValue: V) => V) | V,
) => void {
  return useDynamicBrowserStorageSetter("Local");
}
export function useLocalStorageValue<V>(key: BrowserStorageKey<V>): V {
  return useBrowserStorageValue("Local", key);
}

export function useLocalStorage<V>(
  key: BrowserStorageKey<V>,
): [V, (value: V) => void] {
  const value = useLocalStorageValue(key);
  const setter = useLocalStorageSetter(key);

  return [value, setter];
}
